import React from 'react';

import { IntlProvider } from 'react-intl';
import { isSalonzone } from '../utils';
import messages from '../data/messages/sk';
import salonzoneMessages from '../data/messages/salonzone/sk';

import ForgotPassword from '../marketing/landing/components/common/pages/forgot-password';
import FontSelector from '../components/FontSelector';

const Page = ({ location }) => (
  <IntlProvider
    locale="sk"
    messages={isSalonzone ? salonzoneMessages : messages}
  >
    <FontSelector>
      <ForgotPassword location={location} />
    </FontSelector>
  </IntlProvider>
);

export default Page;
